import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

import '../styles/components/ImageGallery.scss'

function ImageGallery({ data, fullscreen = false }) {
  const images = data.images.nodes
  const className = `image-gallery ${fullscreen ? 'fullscreen' : ''}`

  return (
    <div className={className}>
      {images &&
        images.map((image, i) => (
          <PreviewCompatibleImage
            key={image.name}
            imageInfo={{
              image: image,
              alt: image.name + i,
            }}
          />
        ))}
    </div>
  )
}

ImageGallery.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

const imageGallery = (fullscreen = false) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
          nodes {
            name
            childImageSharp {
              fluid(maxWidth: 800, maxHeight: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `}
    render={(data) => <ImageGallery data={data} fullscreen />}
  />
)

export default imageGallery
