import React from 'react'
import PropTypes from 'prop-types'

import ImageGallery from '../components/ImageGallery'
import Content from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

import '../styles/pages/about.scss'

const AboutPageTemplate = ({ title, content, contentComponent, image }) => {
  const PageContent = contentComponent || Content

  return (
    <div>
      <h1>{title}</h1>
      <div className="about_grid">
        <div className="about_grid__image">
          <PreviewCompatibleImage
            imageInfo={{
              image: image,
              alt: 'Anthony Simpson',
            }}
          />
        </div>
        <div className="about_grid__text">
          <PageContent className="content" content={content} />
        </div>
      </div>
      <ImageGallery fullscreen />
    </div>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  image: PropTypes.object,
}

export default AboutPageTemplate
